<template>
  <div>
    <v-banner dense app sticky class="pl-0">
      <v-alert v-model="hasError" prominent dismissible type="error">
        {{ $t('feedbacks.saveError') }}
      </v-alert>
    </v-banner>

    <v-card
      v-if="!loading"
      class="segmented mb-4"
      color="secondary"
      flat
      rounded
    >
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h4>
              {{
                $t(`feedbacks.processTypes_long.${feedbackData.processType}`)
              }}
            </h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense class="mb-4 mb-sm-0">
          <v-col cols="12" sm="6" md="4">
            {{ $t('feedbacks.type') }}
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <strong>{{ feedbackTitle }}</strong>
          </v-col>
        </v-row>
        <v-row dense class="mb-4 mb-sm-0">
          <v-col cols="12" sm="6" md="4">
            {{ $t('feedbacks.recipient') }}
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <strong>{{ feedbackData.recipientName }}</strong>
            <div class="mt-2">
              <archive-dialog
                :userId="feedbackData.recipientId"
                :userShorthand="feedbackData.recipientShorthand"
              >
              </archive-dialog>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            {{ $t('feedbacks.talkDate') }}
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <strong v-if="feedbackData.talkDate">{{
              $d(new Date(feedbackData.talkDate), 'datetime')
            }}</strong>
          </v-col>
        </v-row>
        <v-row dense class="mb-4 mb-sm-0">
          <v-col cols="12" sm="6" md="4">
            {{ $t('employeeDetails.position') }}
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <strong>{{ localize(feedbackData.recipientPosition) }}</strong>
          </v-col>
        </v-row>
        <v-row dense class="mb-4 mb-sm-0">
          <v-col cols="12" sm="6" md="4">
            {{ $t('feedbacks.donor') }}
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <strong>{{ feedbackData.donorName }}</strong>
          </v-col>
        </v-row>

        <v-row v-if="feedbackData.copyFeedbackId" dense class="mb-4 mb-sm-0">
          <v-col cols="12" sm="6" md="4">
            {{ $t('feedbacks.copyFromArchiveLabel') }}

            <v-tooltip top max-width="500">
              <template v-slot:activator="{ on: tooltip }">
                <v-icon v-on="tooltip">mdi-information</v-icon>
              </template>
              <span>
                {{ $t('feedbacks.copyFromArchiveInfo') }}
              </span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <v-btn small @click="copyFeedbackData" :loading="copyingData">
              <v-icon small class="mr-2">mdi-content-copy</v-icon>
              {{ $t('feedbacks.copyFromArchiveLabelPrompt') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-skeleton-loader
      v-if="loading"
      type="paragraph@3, text@6, paragraph@2, actions"
    ></v-skeleton-loader>

    <template v-else>
      <v-card
        v-if="feedbackData.comments.length > 0"
        class="segmented"
        flat
        rounded
      >
        <v-card-title>
          <h4>{{ $t('comments') }}</h4>
        </v-card-title>
        <v-card-text>
          <div
            v-for="(comment, index) in feedbackData.comments"
            :key="`comment_${index}`"
          >
            <v-subheader class="pa-0 mb-2"
              >{{ getCommentUser(comment) }} -
              {{
                $d(Date.parse(comment.commentDateUtc), 'datetime')
              }}</v-subheader
            >
            <div class="comment-text">
              {{ comment.comment }}
            </div>
            <hr
              v-show="index < feedbackData.comments.length - 1"
              class="mt-4"
            />
          </div>
        </v-card-text>
      </v-card>

      <questionnaire
        :form="feedbackData.formContent"
        :targets="feedbackData.targetItems"
        :readOnly="isReadOnly"
        :canApprove="feedbackData.canApprove"
        :isComparison="feedbackData.isComparison"
        :comparisonItems="feedbackData.comparisonData"
        :comparisonTableItems="feedbackData.tableComparisonData"
        :loading="loading"
        @submit="submit"
        @approve="approve"
        @veto="veto"
        @submissionComplete="submissionComplete"
        @errorOnSave="hasError = true"
        @validationError="hasValidationError = true"
      ></questionnaire>
    </template>

    <v-snackbar v-model="snackbar" color="success" :timeout="timeout">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>{{ $t('toast.dataSaved') }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-snackbar v-model="submitSnackbar" color="success" :timeout="timeout">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>{{ $t('toast.dataSubmitted') }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-snackbar v-model="hasValidationError" color="error" :timeout="timeout">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>Bitte füllen Sie alle Pflichtfelder vollständig aus.</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import questionnaire from '@/components/forms/questionnaire/questionnaire.vue';
import archiveDialog from '@/components/dialogs/process-archive.vue';

export default {
  components: {
    questionnaire,
    archiveDialog
  },

  data: () => ({
    loading: true,
    copyingData: false,
    snackbar: false,
    submitSnackbar: false,
    timeout: 3000,
    hasError: false,
    hasValidationError: false
  }),

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    isAnonymous() {
      return (
        this.$route.params.accessToken !== null &&
        this.$route.params.accessToken !== undefined
      );
    },

    isReadOnly() {
      if (this.feedbackData === null) {
        return true;
      }

      if (this.feedbackData.isSubmitted && this.feedbackData.canUpdate) {
        return false;
      }

      if (!this.feedbackData.isSubmitted && this.feedbackData.canWrite) {
        return false;
      }

      return true;
    },

    feedbackTitle() {
      if (this.feedbackData.title?.length > 0 ?? false) {
        return this.localize(this.feedbackData.title);
      }
      return this.$t(`feedbacks.typeOptions.${this.feedbackData.feedbackType}`);
    }
  },

  watch: {
    async feedbackId() {
      this.loading = true;

      if (this.isAnonymous) {
        await this.loadAnonymousFeedback({
          feedbackId: this.feedbackId,
          accessToken: this.$route.params.accessToken
        });
      } else {
        await this.loadFeedback(this.feedbackId);
      }

      this.loading = true;
    }
  },

  async mounted() {
    await this.loadCustomSettings('hays');

    const feedbackId = this.$route.params.id;

    if (this.isAnonymous) {
      await this.loadAnonymousFeedback({
        feedbackId: feedbackId,
        accessToken: this.$route.params.accessToken
      });
    } else {
      await this.loadFeedback(feedbackId);
    }

    this.loading = false;
  },

  destroyed() {
    this.clearFeedbackData();
  },

  methods: {
    ...mapActions({
      copyFeedback: 'feedbacks/copyFeedbackData',
      loadAnonymousFeedback: 'feedbacks/loadAnonymousFeedback',
      loadFeedback: 'feedbacks/loadFeedback',
      loadCustomSettings: 'forms/loadCustomSettings',
      submitFeedback: 'feedbacks/submitFeedback',
      approveFeedback: 'feedbacks/approveFeedback',
      vetoFeedback: 'feedbacks/vetoFeedback'
    }),

    ...mapMutations({
      clearFeedbackData: 'feedbacks/clearFeedbackData'
    }),

    async copyFeedbackData() {
      this.copyingData = true;
      await this.copyFeedback({
        feedbackData: this.feedbackData,
        currentFeedbackId: this.$route.params.id,
        copyFeedbackId: this.feedbackData.copyFeedbackId
      });

      this.copyingData = false;
    },

    submit() {
      this.submitFeedback(this.$route.params.id);
    },

    approve() {
      this.approveFeedback(this.$route.params.id);
    },

    veto(comment) {
      this.vetoFeedback({
        feedbackId: this.$route.params.id,
        comment: comment
      });
    },

    submissionComplete(mode) {
      if (mode === 'submit') {
        this.submitSnackbar = true;

        setTimeout(() => {
          this.$router.push({ name: 'Dashboard' });
          window.scrollTo(0, 0);
        }, 1000);
      } else {
        this.snackbar = true;
      }
    },

    getCommentUser(comment) {
      if (comment.userName) {
        return comment.userName;
      } else {
        return this.$t('deletedUser');
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-card__title,
.v-card__text {
  table {
    font-size: 0.875rem;

    tr td:first-of-type {
      padding-right: 5em;
    }
  }
}

.comment-text {
  white-space: pre-line;
}

.v-subheader {
  height: auto;
}

::v-deep .v-banner {
  z-index: 2 !important;

  .v-banner__wrapper {
    padding: 0;
    border-bottom: none !important;
  }
}
</style>
